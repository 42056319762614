import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { IConfigData } from '../../submodules/silverlight-common/lib/ts/src/interface/config.data.interface';
import { sourceSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/source.system.enums';
import { APP_CONSTANTS } from '../helpers/app.constants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  static URL = `${environment.api_base_url}/configdata/${APP_CONSTANTS.getApplicationSystemName()}`;
  constructor(public http: HttpClient) {}
  getConfigData(): Observable<any> {
    return this.http.get<IConfigData[]>(ConfigService.URL);
  }
  updateProductTypes(productArray: string[]): Observable<any> {
    const productTypeObject = {
      keyName: 'InjectorTypes',
      keyValue: productArray.join(', '),
    };
    return this.http.put<any>(`${ConfigService.URL}/InjectorTypes`, productTypeObject);
  }
}
