<mat-toolbar color="primary">
  <mat-toolbar-row class="page-title">
    <h1>Dev Access</h1>
  </mat-toolbar-row>
</mat-toolbar>
<div class="scroll-container">
  <div class="container">
    <section>
      <h2>Product Types</h2>
      <mat-form-field class="full-width">
        <mat-label>Product Types</mat-label>
        <mat-chip-grid #chipGrid aria-label="Product Types">
          @for(item of productTypes; track item){
          <mat-chip-row (removed)="removeProductType(item)">
            {{ item }}
            <button matChipRemove class="chip-remove">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          }
        </mat-chip-grid>
        <input Trim placeholder="New Config Item" [matChipInputFor]="chipGrid"
          (matChipInputTokenEnd)="addProductType($event)" [matChipInputAddOnBlur]="true"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
        <mat-icon matSuffix>add</mat-icon>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="saveProductTypes()">
        Save Product Types
      </button>
    </section>

    <section>
      <h2>Purge Logs</h2>
      <mat-form-field class="days-control">
        <mat-label>Days</mat-label>
        <div matSuffix>
          <button mat-icon-button color="primary" (click)="incrementDays()">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="decrementDays()">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
        <input matInput type="number" [formControl]="daysControl" (input)="onInput($event)" />
      </mat-form-field>
      <button mat-raised-button color="warn" (click)="deleteLogs()">
        Purge Logs
      </button>
    </section>

    <section class="full-width-section">
      <h2>Add PLC User</h2>
      <form [formGroup]="userForm">
        <mat-form-field class="full-width">
          <mat-label>Username</mat-label>
          <input Trim maxlength="6" matInput autocomplete="new-username" formControlName="username"
            placeholder="Enter username" />
          @if(userForm.get('username')?.hasError('required')){
          <mat-error>Username is required.</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input Trim matInput type="email" autocomplete="new-email" formControlName="email"
            placeholder="Enter Email" />
          @if(userForm.get('email')?.hasError('required')){
          <mat-error>Username is required.</mat-error>
          } @else if(userForm.get('email')?.hasError('email')){
          <mat-error>Invalid email.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            @for(role of PLCRoles; track role){
            <mat-option [value]="role">{{ plcUserRoleEnum[role] }}</mat-option>
            }
          </mat-select>
          @if (userForm.get('role')?.hasError('required')) {
          <mat-error>Role is required.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Password</mat-label>
          <input Trim matInput autocomplete="new-password" formControlName="password" placeholder="Enter password"
            [type]="hide() ? 'password' : 'text'" />
          <button mat-icon-button matSuffix (click)="toggleViewPassword()" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide()">
            <mat-icon>{{ hide() ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          @if(userForm.get('password')?.hasError('required')){
          <mat-error>Password is required.</mat-error>
          } @else if(userForm.get('password')?.hasError('minlength')){
          <mat-error>Password must be at least 6 characters long.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Confirm Password</mat-label>
          <input Trim matInput formControlName="confirmPassword" placeholder="Confirm password"
            autocomplete="new-password" [type]="hideConfirm() ? 'password' : 'text'" />
          <button mat-icon-button matSuffix (click)="toggleViewPassword('confirm')" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirm()">
            <mat-icon>{{ hideConfirm() ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          @if (userForm.get('confirmPassword')?.hasError('required')) {
          <mat-error>Confirm password is required.</mat-error>
          }
        </mat-form-field>

      </form>
      @if(userForm.hasError('mismatch')){
      <mat-error>Passwords do not match.</mat-error>
      }
      <button mat-raised-button color="primary" [disabled]="userForm.invalid" (click)="addUser()">
        Add User
      </button>
    </section>
  </div>
</div>