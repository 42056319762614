import { ChangeDetectionStrategy, Component} from "@angular/core";
import { RouterModule, RouterOutlet } from "@angular/router";
import { HeaderPanelComponent } from "./header-panel/header-panel.component";
import { BreadCrumbsComponent } from "./bread-crumbs/bread-crumbs.component";
import { AuthService } from "../services/auth.service";
import { environment } from "../environments/environment";


@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, RouterModule, HeaderPanelComponent, BreadCrumbsComponent],
  providers: [AuthService],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
  ) {
    console.log('environment :  ' + environment.api_base_url);
  }
  title = "passlink-management-app";
}
