import { Injectable } from '@angular/core';
import { ICountryList } from '../../submodules/silverlight-common/lib/ts/src/interface/country.list.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { sourceSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/source.system.enums';
import { APP_CONSTANTS } from '../helpers/app.constants';

@Injectable({
  providedIn: 'root',
})
export class CountryListService {
  static URL = `${environment.api_base_url}/CountryList/${APP_CONSTANTS.getApplicationSystemName()}`;
  constructor(public http: HttpClient) {}

  getCountryList(): Observable<any> {
    return this.http.get<ICountryList[]>(CountryListService.URL);
  }
}
