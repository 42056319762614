import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { CreateUserProfileDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/create-user.profile.dto';
import { UpdateUserProfileDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/update-user.profile.dto';
import { environment } from '../environments/environment';
import { IUserProfile } from '../../submodules/silverlight-common/lib/ts/src/interface/user.profile.interface';
import { json } from 'stream/consumers';
import { sourceSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/source.system.enums';
import { APP_CONSTANTS } from '../helpers/app.constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static URL = `${environment.api_base_url}/userProfile/${APP_CONSTANTS.getApplicationSystemName()}`;
  static BulkURL = `${environment.api_base_url}/bulkUserProfile/${APP_CONSTANTS.getApplicationSystemName()}`;

  constructor(public http: HttpClient) {}
  private userProfileListSubject = new BehaviorSubject<IUserProfile[]>([]);

  userProfileList$ = this.userProfileListSubject.asObservable();

  getPLMUserProfiles(): Observable<IUserProfile[]> {
    return this.http
      .get<IUserProfile[]>(`${UserService.URL}/getPLMUserProfiles`)
      .pipe(
        tap((userProfiles) => this.userProfileListSubject.next(userProfiles))
      );
  }

  createUserProfile(
    createUserProfileDto: CreateUserProfileDto
  ): Observable<any> {
    console.log(
      'createUserProfile - userservice:  ',
      JSON.stringify(createUserProfileDto)
    );
    return this.http.post<any>(UserService.URL, createUserProfileDto);
  }

  createBulkUserProfiles(
    userProfileArray: CreateUserProfileDto[]
  ): Observable<IUserProfile> {
    console.log(
      'createUserProfile - userservice:  ',
      JSON.stringify(userProfileArray)
    );
    return this.http.post<IUserProfile>(UserService.BulkURL, userProfileArray);
  }

  updateUserProfile(
    userId: string,
    updateUserProfileDto: UpdateUserProfileDto
  ): Observable<any> {
    return this.http.put<any>(
      `${UserService.URL}/${userId}`,
      updateUserProfileDto
    );
  }
}
