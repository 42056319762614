import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ILogData } from '../../submodules/silverlight-common/lib/ts/src/interface/log.data.interface';
import { environment } from '../environments/environment';
import { sourceSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/source.system.enums';
import { CreateLogDataDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/create-log.data.dto';
import { APP_CONSTANTS } from '../helpers/app.constants';

@Injectable({
  providedIn: 'root'
})
export class ActViewerService {
  static URL = `${environment.api_base_url}/logdata/${APP_CONSTANTS.getApplicationSystemName()}`;
  constructor(public http: HttpClient) { }
  private logDataListSubject = new BehaviorSubject<ILogData[]>([]);

  logDataList$ = this.logDataListSubject.asObservable();
  getAllLogData(): Observable<ILogData[]> {
    return this.http.get<ILogData[]>(ActViewerService.URL).pipe(
      tap((logs) => this.logDataListSubject.next(logs))
    );
  }
  createLogData(createLogDataDto: CreateLogDataDto): Observable<ILogData> {
    return this.http.post<ILogData>(ActViewerService.URL, createLogDataDto);
  }
  deleteLogData(days: number): Observable<any> {
    return this.http.delete<any>(`${environment.api_base_url}/purgelogdata/${APP_CONSTANTS.getApplicationSystemName()}/${days}`);
  }
}
