{
  "name": "passlink-client",
  "version": "1.0.0.20240929233922",
  "appVersion": "1.0.0.0",
  "scripts": {
    "ng": "ng",
    "build": "npm install && node set-build-number.js && ng build",
    "start:local": "clear && ng serve -c local --port 3002",
    "start:dev": "ng serve -c dev --port 4002",
    "start:stg": "ng serve -c stg --port 5002",
    "start:qa": "ng serve -c qa --port 6002",
    "start:prod": "ng serve -c prod --port 7002",
    "build:prod": "ng build --configuration=prod",
    "build:qa": "ng build --configuration=qa",
    "build:stg": "ng build --configuration=stg",
    "build:dev": "ng build --configuration=dev",
    "serve:ssr:workspace": "node dist/workspace/server/server.mjs",
    "e2e": "ng e2e",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.3",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.1.4",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/platform-server": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@angular/ssr": "^18.0.4",
    "@nestjs/common": "10.3.10",
    "@nestjs/config": "3.2.3",
    "class-validator": "^0.14.1",
    "express": "^4.18.2",
    "rxjs": "~7.8.0",
    "silverlight-common-lib-ts": "file:./submodules/silverlight-common/lib/ts",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.4",
    "@angular/cli": "^18.0.4",
    "@angular/compiler-cli": "^18.0.0",
    "@cypress/schematic": "^2.5.2",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "cypress": "latest",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}