import { CreateLogDataDto } from '../../submodules/silverlight-common/lib/ts/src/dtos/create-log.data.dto';
import { logEventEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/log.event.enums';
import { sourceSystemEnum } from '../../submodules/silverlight-common/lib/ts/src/common-enums/source.system.enums';
import { SharedService } from '../services/shared.service';
import { ActViewerService } from '../services/act-viewer.service';
import { GLOBAL_CONSTANTS } from '../../submodules/silverlight-common/lib/ts/src/common-enums/global.constants';
import { APP_CONSTANTS } from './app.constants';

export class LogHandler {
  constructor(private sharedService: SharedService,
    private actViewerService: ActViewerService) {
  }

  captureLogData(logMessage: string, logEventType: logEventEnum): void {
    const createLogDataDto = this.formatLog(logMessage, logEventType);
    this.actViewerService.createLogData(createLogDataDto).subscribe(
      (data: any) => {
        console.log('Log reported at: '
          + GLOBAL_CONSTANTS.PASSLINK_UI_GET_LOCALDATETIME
          + JSON.stringify(data) + ' UTC DATETIME: '
          + GLOBAL_CONSTANTS.PASSLINK_SERVER_GET_CURRENT_UTCDATE
        );
      },
      (error: any) => {
        console.error('Error creating log reported at: '
          + GLOBAL_CONSTANTS.PASSLINK_UI_GET_LOCALDATETIME
          + ' Error: ' + error + ' Message: '
          + logMessage + ' UTC DATETIME: '
          + GLOBAL_CONSTANTS.PASSLINK_SERVER_GET_CURRENT_UTCDATE);
      }
    );
  }

  formatLog(logMessage: string, logEventType: logEventEnum): CreateLogDataDto {
    const createLogDataDto: CreateLogDataDto = {
      logDeviceInfo: 'browser',
      logMessage: logMessage,
      logEventType: logEventType,
      logSrcSystem: APP_CONSTANTS.getApplicationSystemName(),
      logUserName: this.sharedService.getCurrentUserName,
      logEventDateTime: new Date().toISOString(),
    };
    return createLogDataDto;
  }
}
